import RednessDetailTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/redness-detail/RednessDetailTemplate';
import {
  REDNESS_DISCOMFORT_FREQUENCY,
  REDNESS_DURATION
} from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/Redness/RednessDetail/RednessDetailTemplate'
};

const createStory = props => () => ({
  components: { RednessDetailTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <redness-detail-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  rednessDiscomfortFrequency: REDNESS_DISCOMFORT_FREQUENCY.TWO_TO_THREE_WEEKS,
  rednessDuration: REDNESS_DURATION.UNDER_A_YEAR
});

export const WithDuration = createStory({
  rednessDiscomfortFrequency: REDNESS_DISCOMFORT_FREQUENCY.TWO_TO_THREE_WEEKS,
  rednessDuration: REDNESS_DURATION.UNDER_A_YEAR,
  isDurationVisible: true
});
